import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/SmsStoreEnums";
import { FinanceFilter } from "@/types/FinanceModel"

interface StoreInfo {
  financeFilter: FinanceFilter;
}


@Module
export default class FinanceModule extends VuexModule implements StoreInfo {
  financeFilter = {} as FinanceFilter;
  financeRecords = [] as any;

  get currentFinanceFilter(): FinanceFilter {
    return this.financeFilter;
  }

  @Mutation
  [Mutations.SET_FINANCEFILTER_MUTATION](payload) {
    this.financeFilter = payload;
  }

  @Action
  [Actions.SET_FINANCEFILTER_ACTION](payload) {
    this.context.commit(Mutations.SET_FINANCEFILTER_MUTATION, payload);
  }
}
