enum Actions {
    SET_STUDENTPROFILE_ACTION = "setStudentProfileAction",
    SET_FINANCEFILTER_ACTION = "setFinanceFilterAction",
    UPDATE_USER = "updateUser",
  }
  
enum Mutations {
    SET_STUDENTPROFILE_MUTATION = "setStudentProfileMutation",
    SET_FINANCEFILTER_MUTATION = "setFinanceFilterMutation",
    SET_AUTH = "setAuth",
  }
  
  export { Actions, Mutations };
  