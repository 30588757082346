export class Token {
    constructor(accessToken: string|null, expiresIn: string|null, refreshToken: string|null){
        this.accessToken = accessToken;
        this.expiresIn = expiresIn ? new Date(expiresIn) : null;
        this.refreshToken = refreshToken;
    }

    accessToken: string | null;
    expiresIn: Date | null;
    refreshToken: string | null;
}