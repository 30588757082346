import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/SmsStoreEnums";
import { ProfileDetails } from "@/types/StudentModel";

interface StoreInfo {
  studentProfile: ProfileDetails;
}

@Module
export default class StudentModule extends VuexModule implements StoreInfo {
  studentProfile = {} as ProfileDetails;

  get currentStudentProfile(): ProfileDetails {
    return this.studentProfile;
  }

  @Mutation
  [Mutations.SET_STUDENTPROFILE_MUTATION](payload) {
    this.studentProfile = payload;
  }

  @Action
  [Actions.SET_STUDENTPROFILE_ACTION](payload) {
    this.context.commit(Mutations.SET_STUDENTPROFILE_MUTATION, payload);
  }
}
