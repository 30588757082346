import { Token } from "@/types/TokenModel";

const ID_TOKEN_KEY = "id_token" as string;
const EXPIRES_IN = 'expires_in' as string;
const REFRESH_TOKEN = 'refresh_token' as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): Token => {
  return new Token(window.localStorage.getItem(ID_TOKEN_KEY),
    window.localStorage.getItem(EXPIRES_IN),
    window.localStorage.getItem(REFRESH_TOKEN)
  );
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string, expires_in : string|null, refresh_token: string|null): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
  
  if(expires_in){
    window.localStorage.setITem(EXPIRES_IN, expires_in);
  }
  if(refresh_token){
    window.localStorage.setITem(REFRESH_TOKEN, refresh_token);
  }
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem(EXPIRES_IN);
  window.localStorage.removeItem(REFRESH_TOKEN);
};

export default { getToken, saveToken, destroyToken };
