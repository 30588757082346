import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/academics/class",
        name: "class",
        component: () => import("@/views/academics/Class.vue"),
      },
      {
        path: "/academics/class/subject/:id",
        name: "subject",
        component: () => import("@/views/academics/Subject.vue"),
      },
      {
        path: "/academics/attendance",
        name: "attendance",
        component: () => import("@/views/academics/Attendance.vue"),
      },
      {
        path: "/academics/assessments/:subject_id",
        name: "assessments",
        component: () => import("@/views/academics/Assessment.vue"),
      },
      {
        path: "/academics/assessment/:id",
        name: "assessment-detail",
        component: () => import("@/views/academics/AssessmentDetail.vue")
      },
      {
        path: "/student/student-list",
        name: "student-list",
        component: () => import("@/views/student/StudentList.vue"),        
      },
      {
        path: "/student/:id",
        name: "student",
        component: () => import("@/views/student/Student.vue"),   
        children: [
          {
            path: "profile",
            name: "student-profile",
            component: () => import("@/views/student/Profile.vue"),
          },
          {
            path: "academic",
            name: "student-academics",
            component: () => import("@/views/student/Academics.vue"),
          },
          {
            path: "files",
            name: "student-files",
            component: () => import("@/views/student/Files.vue"),
          },
          {
            path: "ledger",
            name: "student-ledger",
            component: () => import("@/views/student/Ledger.vue"),
          },
          {
            path: "memo",
            name: "student-memo",
            component: () => import("@/views/student/Memo.vue"),
          },
          {
            path: "reports",
            name: "student-reports",
            component: () => import("@/views/student/Reports.vue"),
          },
        ],     
      },
      {
        path: "/financial",
        name: "financial",
        component: () => import("@/views/financial/Financial.vue"),        
      },
      {
        path: "/financial/:param",
        name: "financial-param",
        component: () => import("@/views/financial/Financial.vue"),        
      },
      {
        path: "/management/admin-report",
        name: "admin-report",
        component: () => import("@/views/management/AdminReport.vue"),  
      },
      {
        path: "/management/invoice",
        name: "invoice",
        component: () => import("@/views/management/Invoice.vue"),  
      },
      {
        path: "/management/invoice/:param",
        name: "invoice-param",
        component: () => import("@/views/management/Invoice.vue"),  
      },
      {
        path: "/management/admin-report/editor/:id",
        name: "admin-report-editor",
        component: () => import("@/views/management/AdminReportEditor.vue"), 
      },
      {
        path: "/management/agent",
        name: "agent",
        component: () => import("@/views/management/Agent.vue"), 
      },
      {
        path: "/management/agent/:id",
        name: "agent-detail",
        component: () => import("@/views/management/AgentDetail.vue")
      },
      {
        path: "/management/staff",
        name: "staff",
        component: () => import("@/views/management/Staff.vue"), 
      },
      {
        path: "/management/staff/:id",
        name: "staff-detail",
        component: () => import("@/views/management/StaffDetail.vue")
      },
      {
        path: "/management/calendar",
        name: "calendar",
        component: () => import("@/views/management/Calendar.vue"), 
      },
      {
        path: "/management/school-forms",
        name: "school-forms",
        component: () => import("@/views/management/SchoolForms.vue"),  
      },
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/user/Users.vue"), 
      },
      {
        path: "/account/my-account",
        name: "my-account",
        component: () => import("@/views/account/Account.vue"), 
      },
      {
        path: "/crafted/account",
        name: "account",
        component: () => import("@/views/account/Account.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/views/auth/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/auth/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () => import("@/views/auth/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () => import("@/views/auth/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/error/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/error/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  //store.dispatch(Actions.VERIFY_AUTH);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
