import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import SmsAuthModule from "@/store/modules/SmsAuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import StudentModule from "@/store/modules/StudentModule";
import FinanceModule from "@/store/modules/FinanceModule";

config.rawError = true;

const store = createStore({
  modules: {
    SmsAuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    StudentModule,
    FinanceModule
  },
});

export default store;
